import Button from '~/components/buttons/Button'

import * as containerStyles from './SeachModalButton.module.scss'

interface SearchModalButtonProps {
  onOpen: () => void
}

const SearchModalButton = ({ onOpen }: SearchModalButtonProps) => (
  <Button
    type="button"
    name="Search"
    tabIndex={0}
    onClick={onOpen}
    data-id="searchButtonModal"
    customClassName={containerStyles.searchModalButton}
  />
)

export default SearchModalButton
