import Button from '~/components/buttons/Button'
import AnimatedInput from '~/components/forms/fields/text-inputs/AnimatedInput'
import { FormProvider } from '~/components/forms/wrappers/FormProvider'
import { useSubscribeForm } from '~/hooks'

import 'react-toastify/dist/ReactToastify.min.css'

import * as containerStyles from './SubscribeBlogModal.module.scss'

type SubscribeBlogModalProps = {
  onSubmitSuccess: () => void
}

const SubscribeBlogModal = ({ onSubmitSuccess }: SubscribeBlogModalProps) => {
  const { methods, handleSubmit, onSubmit } = useSubscribeForm({
    onSubmitSuccess,
  })

  const {
    formState: { isSubmitting },
  } = methods

  return (
    <FormProvider
      id="subscribeBlogModal"
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      className={containerStyles.subscribeBlogModal__form}
    >
      <AnimatedInput
        name="name"
        type="name"
        label="Your Name *"
        data-id="subscribeBlogModalName"
        dataIdError="subscribeBlogModalNameError"
      />
      <AnimatedInput
        name="email"
        type="email"
        label="E-mail *"
        data-id="subscribeBlogModalEmail"
        dataIdError="subscribeBlogModalEmailError"
      />
      <Button
        isRed
        type="submit"
        name="Subscribe"
        disabled={isSubmitting}
        dataId="subscribeBlogModalButton"
        id="subscribeBlogModalButton"
        customClassName={containerStyles.subscribeBlogModal__button}
      />
    </FormProvider>
  )
}

export default SubscribeBlogModal
