import { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

import SearchInput from '~/components/shared/SearchInput'
import { useModal } from '~/hooks/useModal'

import SearchModalButton from './components/SeachModalButton'
import * as containerStyles from './SearchModal.module.scss'

import './SearchModal.scss'

const SearchModal = ({ data }) => {
  const { open, showModal, hideModal } = useModal()

  useEffect(() => {
    if (open) {
      sessionStorage.setItem('isModalOpen', 'true')
    } else {
      sessionStorage.removeItem('isModalOpen')
    }
  }, [open])

  return (
    <>
      <SearchModalButton onOpen={showModal} />

      <Modal
        centered
        show={open}
        onHide={hideModal}
        className="searchModal"
        aria-labelledby="search-modal"
      >
        <Modal.Header closeButton>
          <div className={containerStyles.searchModal__modalTitle}>Search</div>
        </Modal.Header>

        <Modal.Body>
          <SearchInput data={data} isTags />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SearchModal
