import { navigate } from 'gatsby'
import { FormEvent, useEffect, useState } from 'react'

import searchIcon from '~/assets/images/icons/icon-search-blue.svg'
import Button from '~/components/buttons/Button'
import { IS_BROWSER } from '~/constants/browser'
import { AllStrapiTag } from '~/types/graphql'
import { cx } from '~/utils'
import NavigationTag from '~/views/Blog/components/NavigationTag'

import * as containerStyles from './SearchInput.module.scss'

interface SearchInputProps {
  data?: AllStrapiTag
  isTags?: boolean
}

const SearchInput = ({ data, isTags }: SearchInputProps) => {
  const { search, pathname } = IS_BROWSER
    ? window.location
    : { search: '', pathname: '' }

  const searchParams = new URLSearchParams(search)
  const query = searchParams.get('query')

  const [searchQuery, setSearchQuery] = useState(query || '')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(false)
  }, [pathname, search])

  const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const trimmedQuery = searchQuery.trim()
    if (!searchQuery || trimmedQuery === query) return

    setIsLoading(true)

    const formattedSearchQuery = trimmedQuery.replace(/\s+/g, '-')

    navigate(`/blog/search/?query=${formattedSearchQuery}`, { replace: true })

    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }

  return (
    <>
      <form
        role="search"
        id="search-form"
        aria-label="Search the blog"
        onSubmit={onSubmitForm}
        className={containerStyles.search__form}
      >
        <input
          name="search"
          type="search"
          id="header-search"
          disabled={isLoading}
          value={searchQuery.replace(/-/g, ' ')}
          onChange={(e) => setSearchQuery(e.target.value)}
          className={containerStyles.search__input}
          placeholder="What are you looking for?"
        />
        <Button
          type="submit"
          name="Search"
          id="searchButton"
          dataId="searchButton"
          disabled={isLoading}
          customClassName={cx(
            containerStyles.search__btn,
            isLoading && containerStyles.search__loader,
          )}
        >
          <img
            src={searchIcon}
            alt="Search | Codica"
            title="Search"
            height={32}
            width={32}
            loading="lazy"
          />
        </Button>
      </form>

      {isTags && data && (
        <>
          <div className={containerStyles.search__text}>
            or try ready-made search queries
          </div>

          <div className="row no-gutters">
            <NavigationTag data={data} isCenter showMore />
          </div>
        </>
      )}
    </>
  )
}

export default SearchInput
